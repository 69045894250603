import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet"
import useFirebase from '../components/useFirebase';
import moment from 'moment';

moment.locale('nl');

import Layout from '../components/layout';

const reverseObject = (obj) => {
  const newObj = {};
  Object.keys(obj).forEach(key => {
     if(newObj[obj[key]]){
        newObj[obj[key]].push(key);
     }else{
        newObj[obj[key]] = [key];
     }
  });
  return newObj;
};

function useGuestbookList() {
  const firebase = useFirebase();
  const [lists, setLists] = useState([]);

  useEffect(() => {
    if (!firebase) return;

    firebase
      .database()
      .ref('/gastenboek')
      .on('value', querySnapShot => {
        var messages = [];
        querySnapShot.forEach((data) => {
          messages.push(data.val());
        });
        messages.reverse()
        setLists(messages);
      });
  }, [firebase]);

  return lists;
}

function GuestbookPage() {
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const guestbookList = useGuestbookList();
  const firebase = useFirebase();
  const [isSent, toggleSent] = useState(false);
  const [rating, setRating] = useState('');
  const [hover, setHover] = useState(0);

  const submit = e => {
    e.preventDefault();

    firebase
      .database()
      .ref('/gastenboek')
      .push()
      .set({
        naam: name,
        message: message,
        rating: rating,
        datum: Date.now(),
      });

      setName('');
      setMessage('');
      setRating('');

      toggleSent(true);
  };

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Gastenboek - Bruiloft Thom en Cynthia</title>
      </Helmet>
      <div className="mx-auto lg:w-5/6 text-center">
        <h1>Gastenboek</h1>
      </div>
      <section className="mx-auto lg:w-5/6 text-center">
        <p>Was het eten niet te vreten of was het een dag om nooit te vergeten. Laat het ons weten zodra deze dag is geweest.<br /><br /></p>
      </section>


      <section className={`${isSent ? `hidden` : `block`}`}>
        <form className="mx-auto lg:w-5/6" onSubmit={submit}>
          <label
            className="block mb-2 italic"
            htmlFor="name"
          >
            Naam
          </label>

          <input
            className="appearance-none block bg-gray-200 mb-6 px-3 py-2 rounded-md text-gray-700 w-full"
            id="name"
            placeholder="Naam"
            type="text"
            value={name}
            onChange={e => setName(e.target.value)}
            required
          />

          <label
            className="block mb-2 italic"
            htmlFor="message"
          >
            Laat weten hoe leuk je het vond
          </label>

          <div className="star-rating mb-5">
            <div className="inline-block px-3 py-2 rounded-md">
              {[...Array(5)].map((star, index) => {
                index += 1;
                return (
                  <button
                    type="button"
                    key={index}
                    className={index <= (hover || rating) ? "on" : "off"}
                    onClick={() => setRating(index)}
                    onMouseEnter={() => setHover(index)}
                    onMouseLeave={() => setHover(rating)}
                  >
                    <span className="star">&#9733;</span>
                  </button>
                );
              })}
            </div>
          </div>

          <label
            className="block mb-2 italic"
            htmlFor="message"
          >
            Bericht
          </label>

          <textarea
            className="appearance-none bg-gray-200 mb-6 px-3 py-2 rounded-md text-gray-700 w-full"
            id="message"
            placeholder="Laat je bericht achter&hellip;"
            value={message}
            onChange={e => setMessage(e.target.value)}
            required
            rows="6"
          />

          <button
            type="submit"
            className="border-b-4 bg-black px-4 py-2 rounded text-white"
          >
            Versturen
          </button>
        </form>
      </section>
      <section
        className={`${
          isSent ? `block` : `hidden`
        } mx-auto lg:w-5/6 text-center`}
      >
        <br />
        <p>Bedankt voor het achterlaten van je bericht!</p>
      </section>
      <section className="mt-6 mx-auto lg:w-5/6">
        {Object.keys(guestbookList).map(obj => {
          return (
            <div key={obj} className="mb-4">
              <strong>{guestbookList[obj].naam} op {moment(guestbookList[obj].datum).locale('nl').format("DD MMMM YYYY")}</strong>
              <p>
                {guestbookList[obj].rating.isInteger}
                {guestbookList[obj].rating !== '' && (
                  <>
                    {[...Array(guestbookList[obj].rating)].map((star, index) => {
                      index += 1;
                      return (
                        <span key={index} className="star" style={{color: '#f1cd50'}}>&#9733;</span>
                      );
                    })}
                  </>
                )}
              </p>
              <p className="mt-2">{guestbookList[obj].message}</p>
            </div>
          );
        })}
      </section>
    </Layout>
  );
}

export default GuestbookPage;
